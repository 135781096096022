import type { VizzlyAccessTokens } from '@/types'

export function vizzlyChartsRepository() {
  const { $api } = useNuxtApp()

  async function getJWTTokens(): Promise<Ref<VizzlyAccessTokens | null>> {
    const payload = ref<VizzlyAccessTokens | null>(null)

    try {
      const response = await $api(`/vizzly/tokens`, {
        method: 'GET',
      }) as VizzlyAccessTokens

      payload.value = response
    }
    catch (error) {
      payload.value = {
        dataAccessToken: '',
        dashboardAccessToken: '',
      }
    }

    return payload as Ref<VizzlyAccessTokens>
  }

  return {
    getJWTTokens,
  }
}
