import { defineStore } from 'pinia'

import type { VizzlyAccessTokens } from '@/types'
import { vizzlyChartsRepository } from '@/repositories'

export const useVizzlyChartsStore = defineStore('vizzly-charts', () => {
  const supabaseUser = useSupabaseUser()
  const vizzlyAccessTokens = ref<VizzlyAccessTokens & { loading: boolean }>({
    dataAccessToken: '',
    dashboardAccessToken: '',
    loading: false,
  })

  const {
    getJWTTokens: getJWTTokensRepository,
  } = vizzlyChartsRepository()

  async function getJWTTokens() {
    vizzlyAccessTokens.value.loading = true

    if (supabaseUser.value?.id) {
      const { dataAccessToken, dashboardAccessToken } = ((await getJWTTokensRepository()).value || {})!

      if (dataAccessToken && dashboardAccessToken) {
        vizzlyAccessTokens.value = {
          dataAccessToken,
          dashboardAccessToken,
          loading: false,
        }
        return vizzlyAccessTokens.value
      }
    }
    else {
      // eslint-disable-next-line  no-console
      console.log('ERROR: User is not logged in')
    }
  }

  return {
    getJWTTokens,
  }
})
